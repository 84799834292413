<template>
  <main-template>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col>
            <h1>
              <v-btn color="primary" x-small text @click="goBack">
                <v-icon left> fa fa-angle-left </v-icon>
              </v-btn>
              Aulas
            </h1>
          </v-col>
        </v-row>
        <v-card v-if="gestaoDeAula">
          <v-row>
            <v-col cols="4">
              <v-card-text>
                <h4>
                  Turma:
                  {{ gestaoDeAula.turma ? gestaoDeAula.turma.descricao : "- - -" }}
                </h4>

                <h4>
                  Disciplina:
                  {{ gestaoDeAula.disciplina ? gestaoDeAula.disciplina.descricao : "- - -" }}
                </h4>
                <h4>
                  Carga horária:
                  {{
                    gestaoDeAula.carga_horaria
                      ? gestaoDeAula.carga_horaria
                      : "[ SEM CARGA HORÁRIA ]"
                  }}
                </h4>
                <h4>
                  Professor:
                  {{ gestaoDeAula.professor ? gestaoDeAula.professor.nome : "- - -" }}
                </h4>
              </v-card-text>
              <v-col cols="12" class="py-2">
                <v-btn-toggle borderless>
                  <v-btn value="left" @click="() => openModalMonth(1)">
                    <span class="hidden-sm-and-down">Frequência</span>
                    <v-icon right> mdi-file-pdf </v-icon>
                  </v-btn>

                  <v-btn value="center" @click="() => openModalMonth(2)">
                    <span class="hidden-sm-and-down">Aulas</span>

                    <v-icon right> mdi-file-pdf </v-icon>
                  </v-btn>

                  <v-btn v-if="curso.id !== 10" value="right" @click="() => diarioNotas()">
                    <span class="hidden-sm-and-down">Notas</span>

                    <v-icon right> mdi-file-pdf </v-icon>
                  </v-btn>

                  <v-btn v-if="curso.id === 10" value="right" @click="() => RelatorioBNCCTurma()">
                    <span class="hidden-sm-and-down">Relatório BNCC</span>
                    <v-icon right> mdi-file-pdf </v-icon>
                  </v-btn>

                  <v-btn value="right" @click="() => verHistoricoAulas()">
                    <span class="hidden-sm-and-down">Histórico de aulas</span>

                    <v-icon right> mdi-poll </v-icon>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-col>
            <v-col cols="8" class="d-flex justify-end">
              <div class="text-right">
                <e-pie-aulas-lancadas :relatorio="relatorio" />
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-col cols="12" class="pt-3 px-0 mt-3">
          <div class="d-flex justify-space-between">
            <div>
              <template v-if="user.ano_id <= 3">
                <v-btn color="success" @click="() => criarAula(gestaoDeAula)" class="mr-2"
                  >Criar Aula</v-btn
                >
              </template>
              <template v-else>
                <v-btn color="success" @click="() => criarAulaComRotina(gestaoDeAula)" class="mr-2"
                  >Criar Aula</v-btn
                >
              </template>
            </div>
            <v-text-field
              v-model="search"
              class="ml-2 search-input"
              append-icon="mdi-magnify"
              label="Pesquisar..."
              solo
              autofocus
              dense
              single-line
              hide-details
            />
          </div>
        </v-col>
      </v-col>
    </v-row>
    <v-row>
      <template v-if="user.ano_id <= 3">
        <v-col class="pt-0">
          <v-data-table
            :headers="table.headers"
            :items="aulasList"
            :loading="carregandoAulas"
            :items-per-page="10"
            :custom-filter="dataTableFilter"
            :search="search"
            class="elevation-1"
          >
            <template v-slot:body="{ items }">
              <tbody name="scroll-x-transition" is="transition-group" duration="150">
                <tr
                  color="primary"
                  :class="aula.estaDeletado ? ['deleted'] : []"
                  v-for="(aula, i) in items"
                  :key="aula.id"
                >
                  <td>{{ aula.id }}</td>
                  <td>{{ aula.data_formatada }}</td>
                  <td class="d-flex align-center">
                    <e-situacao-aula :aula="aula" :i="i" @alterarSituacao="alterarSituacao" />
                    <v-chip
                      class="ml-2"
                      v-if="aula.descricaoSituacao === 'Aula em conflito'"
                      color="orange"
                      x-small
                      dark
                      @click="() => verificaSituacao(aula.id)"
                    >
                      ?</v-chip
                    >
                  </td>
                  <td><e-tipo-aula-snippet :aula="aula" /></td>

                  <td class="text-left">
                    <e-resumo-horarios
                      :items="aula.horarios_vinculado ? aula.horarios_vinculado : []"
                    />
                  </td>

                  <td class="text-right" width="25%">
                    <v-tooltip
                      color="primary"
                      v-if="parseInt(gestaoDeAula.turma.notifiq, 10) === 1"
                      bottom
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          class="ml-1"
                          :color="parseInt(aula.frequencia_notifiq, 10) === 1 ? 'primary' : 'info'"
                          v-bind="attrs"
                          v-on="on"
                          @click="() => enableSubmit(aula)"
                        >
                          <span class="material-icons">
                            {{ parseInt(aula.frequencia_notifiq, 10) === 1 ? "link" : "link_off" }}
                          </span>
                        </v-btn>
                      </template>
                      <span>
                        {{
                          parseInt(aula.frequencia_notifiq, 10) === 1
                            ? "Registro de presenca automático"
                            : "Registro de presenca manual"
                        }}
                      </span>
                    </v-tooltip>
                    <v-tooltip color="primary" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          class="ml-1"
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          @click="() => verMotivoAula(aula)"
                        >
                          <v-icon small>fa fa-eye </v-icon>
                        </v-btn>
                      </template>
                      <span>Visualizar aula</span>
                    </v-tooltip>
                    <v-tooltip v-if="!aula.estaDeletado" bottom>
                      <!-- :disabled=" aula.descricaoSituacao !== 'Aguardando confirmação' ?
                      !$can(['aulas.atualizarAposDefinirSituacao']) : false " -->
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          class="ml-1"
                          color="blue"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="bloquearCampoFrequencia(aula) ? true : false"
                          :to="{
                            name: 'aulas.frequencia',
                            params: { gestaoDeAulaId, aula_id: aula.id },
                          }"
                        >
                          <v-icon small>fa fa-thumbs-up </v-icon>
                        </v-btn>
                      </template>
                      <span>Lançar Frequência</span>
                    </v-tooltip>

                    <!-- para bloquear as aulas -->
                    <!-- v-if="aula.metodologia == null && aula.turma.curso_id == 10" -->

                    <v-tooltip v-if="!aula.estaDeletado" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          class="ml-1"
                          color="blue-grey lighten-3"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="bloquearCampo(aula) ? true : false"
                          @click="() => editarAula(aula, gestaoDeAula)"
                        >
                          <v-icon small>fa fa-edit</v-icon>
                          <!-- {{ aula.gestao_antiga_id || "---" }} -->
                        </v-btn>
                      </template>
                      <span>Editar Aula </span>
                    </v-tooltip>
                    <v-tooltip v-if="!aula.estaDeletado" color="error" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          class="ml-1"
                          color="error"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="bloquearCampo(aula) ? true : false"
                          @click="() => deletarAula(aula)"
                        >
                          <v-icon small>fa fa-trash </v-icon>
                        </v-btn>
                      </template>
                      <span>Apagar Aula </span>
                    </v-tooltip>
                    <v-tooltip v-if="aula.estaDeletado" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          class="ml-1"
                          color="info"
                          v-bind="attrs"
                          v-on="on"
                          @click="() => recuperarAula(aula)"
                        >
                          <v-icon small>fa fa-sync </v-icon>
                        </v-btn>
                      </template>
                      <span>Recuperar Aula </span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
      </template>

      <template v-else>
        <v-col class="pt-0">
          <v-data-table
            :headers="table.headersComMomentos"
            :items="aulasList"
            :loading="carregandoAulas"
            :items-per-page="10"
            :custom-filter="dataTableFilter"
            :search="search"
            class="elevation-1"
          >
            <template v-slot:body="{ items }">
              <tbody name="scroll-x-transition" is="transition-group" duration="150">
                <tr
                  color="primary"
                  :class="aula.estaDeletado ? ['deleted'] : []"
                  v-for="(aula, i) in items"
                  :key="aula.id"
                >
                  <td>{{ aula.id }}</td>
                  <td>{{ aula.formatted_created_at }}</td>
                  <td>{{ aula.data_formatada }}</td>
                  <td class="d-flex align-center">
                    <e-situacao-aula :aula="aula" :i="i" @alterarSituacao="alterarSituacao" />
                    <v-chip
                      class="ml-2"
                      v-if="aula.descricaoSituacao === 'Aula em conflito'"
                      color="orange"
                      x-small
                      dark
                      @click="() => verificaSituacao(aula.id)"
                    >
                      ?</v-chip
                    >
                  </td>
                  <td><e-tipo-aula-snippet :aula="aula" /></td>

                  <td class="text-left">
                    {{ getDescricaoMomento(aula.momento)[0] }}
                    {{ getDescricaoMomento(aula.momento)[1] }}
                  </td>

                  <td class="text-right" width="25%">
                    <v-tooltip
                      color="primary"
                      v-if="parseInt(gestaoDeAula.turma.notifiq, 10) === 1"
                      bottom
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          class="ml-1"
                          :color="parseInt(aula.frequencia_notifiq, 10) === 1 ? 'primary' : 'info'"
                          v-bind="attrs"
                          v-on="on"
                          @click="() => enableSubmit(aula)"
                        >
                          <span class="material-icons">
                            {{ parseInt(aula.frequencia_notifiq, 10) === 1 ? "link" : "link_off" }}
                          </span>
                        </v-btn>
                      </template>
                      <span>
                        {{
                          parseInt(aula.frequencia_notifiq, 10) === 1
                            ? "Registro de presenca automático"
                            : "Registro de presenca manual"
                        }}
                      </span>
                    </v-tooltip>
                    <v-tooltip v-if="user.ano_id <= 3" color="primary" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          class="ml-1"
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          @click="() => verMotivoAula(aula)"
                        >
                          <v-icon small>fa fa-eye </v-icon>
                        </v-btn>
                      </template>
                      <span>Visualizar aula </span>
                    </v-tooltip>
                    <v-tooltip v-else color="primary" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          class="ml-1"
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          @click="() => verMotivoAula(aula)"
                        >
                          <v-icon small>fa fa-eye </v-icon>
                        </v-btn>
                      </template>
                      <span>Visualizar aula</span>
                    </v-tooltip>
                    <v-tooltip v-if="!aula.estaDeletado" bottom>
                      <!-- :disabled=" aula.descricaoSituacao !== 'Aguardando confirmação' ?
                      !$can(['aulas.atualizarAposDefinirSituacao']) : false " -->
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          class="ml-1"
                          color="blue"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="bloquearCampoFrequencia(aula) ? true : false"
                          :to="{
                            name: 'aulas.frequencia',
                            params: { gestaoDeAulaId, aula_id: aula.id },
                          }"
                        >
                          <v-icon small>fa fa-thumbs-up </v-icon>
                        </v-btn>
                      </template>
                      <span>Lançar Frequência</span>
                    </v-tooltip>

                    <!-- para bloquear as aulas -->
                    <!-- v-if="aula.metodologia == null && aula.turma.curso_id == 10" -->

                    <v-tooltip v-if="!aula.estaDeletado" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          class="ml-1"
                          color="blue-grey lighten-3"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="bloquearCampo(aula) ? true : false"
                          @click="() => editarAulaComMomentos(aula, gestaoDeAula)"
                        >
                          <v-icon small>fa fa-edit</v-icon>
                          <!-- {{ aula.gestao_antiga_id || "---" }} -->
                        </v-btn>
                      </template>
                      <span>Editar Aula </span>
                    </v-tooltip>
                    <v-tooltip v-if="!aula.estaDeletado" color="error" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          class="ml-1"
                          color="error"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="bloquearCampo(aula) ? true : false"
                          @click="() => deletarAula(aula)"
                        >
                          <v-icon small>fa fa-trash </v-icon>
                        </v-btn>
                      </template>
                      <span>Apagar Aula </span>
                    </v-tooltip>
                    <v-tooltip v-if="aula.estaDeletado" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          class="ml-1"
                          color="info"
                          v-bind="attrs"
                          v-on="on"
                          @click="() => recuperarAula(aula)"
                        >
                          <v-icon small>fa fa-sync </v-icon>
                        </v-btn>
                      </template>
                      <span>Recuperar Aula </span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
      </template>

      <v-bottom-sheet v-model="aulasEmConflitos" scrollable>
        <v-sheet class="text-center pa-md-8 mx-lg-auto" height="auto">
          <v-btn color="error" dark small @click="aulasEmConflitos = !aulasEmConflitos">
            Fechar
          </v-btn>

          <v-alert
            class="mt-2"
            style="text-align: center"
            type="orange"
            v-if="aulasConflitadasEncontradas"
          >
            Procurando aulas em conflitos
          </v-alert>

          <h2 class="mt-4">Aulas conflitadas</h2>
          <v-simple-table class="mt-4" style="border: 1px solid #e0e0e0">
            <template>
              <thead style="background-color: #e0e0e0">
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">Professor</th>
                  <th class="text-left">Situação</th>
                  <th class="text-left">Data/Horario</th>
                  <th class="text-left">Turma</th>
                  <th class="text-left">Disciplina</th>
                </tr>
              </thead>
              <tbody class="text-left">
                <tr v-if="aulasConflitadas.length <= 0">
                  <td>SEM AULAS EM CONFLITOS</td>
                </tr>
                <tr v-for="item in aulasConflitadas" :key="item.id">
                  <td>{{ item.id }}</td>
                  <td>
                    {{ item.professor.nome }}
                    <br />
                    <v-chip color="grey" dark x-small>
                      {{ item.turma.franquia.descricao }}
                    </v-chip>
                  </td>
                  <td>{{ item.situacao }}</td>
                  <td>
                    <span>
                      {{ item.data.split("-").reverse().join("/") }}
                    </span>
                    - {{ item.horario.descricao }}
                  </td>
                  <td>{{ item.turma.descricao }}</td>
                  <td>{{ item.disciplina.descricao }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-sheet>
      </v-bottom-sheet>
    </v-row>
    <e-drawer-motivo-aula v-model="verMotivo" :aula="aula"> </e-drawer-motivo-aula>
    <e-drawer-historico-aulas v-model="openDrawer" :gestao_id="parseInt(gestaoDeAulaId, 10)">
    </e-drawer-historico-aulas>
    <!-- Dialog -->
    <e-modal-select-month-aula
      :dialog="dialog"
      @dialogChange="dialog = $event"
      :gestao_id="gestaoDeAulaId"
      :tipo_diario="tipo_diario"
    ></e-modal-select-month-aula>
    <e-modal-confirm
      :confirmDialog="confirmDialog"
      :objectModal="objectModal"
      @changeModalConfirm="enableSubmit"
    >
      <!-- @changeActionResponse="removerRegistroPeloNotifiq" -->
    </e-modal-confirm>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import { dataTableFilter } from "@/plugins/searchQuery";

export default {
  props: {
    gestaoDeAulaId: {
      required: true,
      type: [String, Number],
    },
  },
  data() {
    return {
      aulasConflitadasEncontradas: false,
      curso: {},
      openDrawer: false,
      aulasConflitadas: [],
      aulasEmConflitos: false,
      dataTableFilter,
      aulasList: [],
      carregandoAulas: false,
      relatorio: [],
      gestaoDeAulas: null,
      verMotivo: false,
      dialog: false,
      tipo_diario: 0,
      aula: {},
      gestaoDeAula: null,
      aula_notifiq: null,
      confirmDialog: false,
      objectModal: {
        title: "Notifiq",
        message:
          "Essa aula está sendo rigistrada automaticamente pelo notifiq, deseja realmente deixar esse registro manual?",
      },
      search: "",
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Data", value: "data_formatada" },
          { text: "Situação", value: "situacao" },
          { text: "Tipo", value: "tipo" },
          { text: "Horário", value: "horario_descricao" },
          {
            text: "Ações",
            value: "actions",
            sortable: false,
          },
        ],
        headersComMomentos: [
          { text: "#", value: "id" },
          { text: "Data", value: "created_a" },
          { text: "Dia letivo", value: "data_formatada" },
          { text: "Situação", value: "situacao" },
          { text: "Tipo", value: "tipo" },
          { text: "Momentos", value: "momento" },
          {
            text: "Ações",
            value: "actions",
            sortable: false,
          },
        ],
      },
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  methods: {
    getDescricaoMomento(itemSelecionado) {
      const momentoSelecionadoDescricao = [];
      const momentoSelecionado = itemSelecionado;

      this.$constants.rotinasTurma.map((item) => {
        return item.children.map((subItem) => {
          // console.log(subItem.id);
          if (subItem.id === momentoSelecionado) {
            const pai = subItem.pai;

            const paiName = this.$constants.rotinasTurma.filter((item) => item.id === pai)[0].name;

            // console.log("Pai:", pai, "Nome:", paiName);

            momentoSelecionadoDescricao.push(paiName);
            momentoSelecionadoDescricao.push(subItem.name);
          }
        });
      });

      return momentoSelecionadoDescricao;
    },

    bloquearCampo(aula) {
      if (
        aula.descricaoSituacao === "Aula confirmada" ||
        aula.descricaoSituacao === "Aula inválida" ||
        aula.descricaoSituacao === "Aula rejeitada por falta" ||
        (aula.professor_antigo_id !== null && this.user.roles[0].name === "professor")
      ) {
        return true;
      }
      return false;
    },

    bloquearCampoFrequencia(aula) {
      if (
        this.user.roles[0].name === "professor" ||
        this.user.roles[0].name === "professor (PEI)"
      ) {
        if (
          aula.descricaoSituacao === "Aula confirmada" ||
          aula.descricaoSituacao === "Aula inválida" ||
          aula.descricaoSituacao === "Aula rejeitada por falta" ||
          (aula.professor_antigo_id !== null && this.user.roles[0].name === "professor")
        ) {
          return true;
        }
      }
      return false;
    },

    enableSubmit(aula = null) {
      this.confirmDialog = !this.confirmDialog;
      if (aula) {
        this.aula_notifiq = {
          ...aula,
          frequencia_notifiq: parseInt(aula.frequencia_notifiq, 10) === 1 ? 0 : 1,
          gestao_de_aula: parseInt(this.$route.params.gestaoDeAulaId, 10),
        };
        return;
      }
      this.aula_notifiq = aula;
    },
    goBack() {
      this.$router.push({ name: "gestoesDeAulas" });
    },
    openModalMonth(tipo_diario) {
      this.dialog = true;
      this.tipo_diario = parseInt(tipo_diario, 10);
    },
    criarAulaComRotina(gestaoDeAula) {
      this.$router.push({
        name: "aulas.create.rotina",
        params: {
          gestaoDeAulaId: gestaoDeAula.id,
        },
      });
    },
    criarAula(gestaoDeAula) {
      this.$router.push({
        name: "aulas.create",
        params: {
          gestaoDeAulaId: gestaoDeAula.id,
        },
      });
    },
    async diarioNotas() {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.diarioNotas(this.gestaoDeAulaId);
        this.$emit("dialogChange", !this.dialog);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    verMotivoAula(aula) {
      this.verMotivo = !this.verMotivo;
      this.aula = aula;
    },
    editarAula(aula) {
      this.$router.push({
        name: "aulas.edit",
        params: { aula_id: aula.id },
      });
    },
    editarAulaComMomentos(aula) {
      this.$router.push({
        name: "aulas.edit.momentos",
        params: { aula_id: aula.id },
      });
    },
    alterarSituacao(Objeto) {
      this.$set(this.aulasList, Objeto.i, Objeto.aulaAtualizada);
      this.loadData();
    },
    async verificaSituacao(aula_id) {
      this.aulasEmConflitos = !this.aulasEmConflitos;
      const data = await this.$services.aulasService.listarAulasEmConflitos(aula_id);
      this.aulasConflitadas = data.data;
      if (data.data.length === 0) {
        this.$services.aulasService.atualizarSituacaoDaAula(aula_id).then(() => {
          // this.$router.go();
          this.$forceUpdate();
          window.location.reload();
        });
      }
    },
    async loadData() {
      this.carregandoAulas = true;
      // this.$loaderService.open("Carregando Aulas");
      try {
        // const response = await this.$services.gestoesDeAulasService.listarAulasGestaoPaginada(
        //   this.gestaoDeAulaId
        // );

        // this.aulasList = response.aulas;
        // this.gestaoDeAula = response.payload.gestaoDeAula;
        // this.relatorio = response.payload.relatorio;
        // this.curso = {
        //   ...this.gestaoDeAula.turma.curso,
        // };

        // console.log(response);

        const response = await this.$services.gestoesDeAulasService.listarAulasGestao(
          this.gestaoDeAulaId
        );
        this.aulasList = response.aulas;
        this.gestaoDeAula = response.payload.gestaoDeAula;
        this.relatorio = response.payload.relatorio;
        this.curso = {
          ...this.gestaoDeAula.turma.curso,
        };
      } catch (error) {
        this.$handleError(error);
      }
      // this.$loaderService.close();
      this.carregandoAulas = false;
    },

    async deletarAula(aula) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esta aula?",
        confirmationCode: aula.id,
        confirmationMessage: `Por favor, digite <strong>${aula.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a Aula");
              try {
                await this.$services.aulasService.deletar(aula);
                this.aulasList = this.aulasList.filter((item) => item.id !== aula.id);
                this.$toast.success("Aula deletada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              small: true,
            },
          },
        ],
      });
    },

    recuperarAula(aula) {
      this.$modals.warn({
        title: "Atenção!",
        message: "Tem certeza que deseja recuperar esta aula?",
        confirmationCode: aula.id,
        confirmationMessage: `Por favor, digite <strong>${aula.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Recuperando a Aula");
              try {
                await this.$services.aulasService.recuperarAula(aula);
                this.$toast.success("Aula recuperada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },

    verHistoricoAulas() {
      this.openDrawer = !this.openDrawer;
    },

    async RelatorioBNCCTurma() {
      try {
        this.$loaderService.open("Carregando relátorio");
        await this.$services.relatoriosService.relatorioBNCCTurma(
          parseInt(this.gestaoDeAula.turma.id, 10)
        );
        this.$loaderService.close();
      } catch (error) {
        this.$loaderService.close();
        this.$handleError(error);
      }
    },
  },
};
</script>

<style></style>
