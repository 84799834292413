<template>
  <div class="template-wrapper">
    <div>
      <e-left-menu />
    </div>
    <div class="main-content">
      <v-app-bar elevate-on-scroll width="100%" color="primary" style="overflow: hidden">
        <v-btn icon @click="toggleMenu" class="blue--text">
          <v-icon> mdi mdi-menu </v-icon>
        </v-btn>
        <span class="text-subtitle-1 d-none d-sm-block" style="color: #212ad2" v-if="!hideFranquia">
          {{ user ? user.franquia.descricao : "..." }}
        </span>
        <v-spacer />
        <v-list color="transparent" max-width="260px" class="pa-0">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-avatar>
                  <e-user-avatar current />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="white--text">
                    {{ (user ? user.name : "Carregando...") | truncate(15) }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <e-user-role link />
                  </v-list-item-subtitle> </v-list-item-content
                ><v-list-item-action>
                  <v-icon>mdi-menu-down</v-icon>
                </v-list-item-action>
              </v-list-item>
            </template>
            <v-list dense>
              <!-- <v-list-item
                v-if="user?.roleName === 'super-admin' && dadosOcultos === 0"
                dense
                link
                @click="mostrarDadosOcultos(1)"
              >
                <v-list-item-avatar>
                  <v-icon small>visibility</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Mostrar dados ocultos</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-else-if="user?.roleName === 'super-admin' && dadosOcultos === 1"
                dense
                link
                @click="mostrarDadosOcultos(0)"
              >
                <v-list-item-avatar>
                  <v-icon small>visibility_off</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Desativar dados ocultos</v-list-item-title>
              </v-list-item> -->
              <v-list-item dense link @click="openSelecionarFranquiaModal = true">
                <v-list-item-avatar>
                  <v-icon small>fa fa-cogs</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Alterar unidade escolar</v-list-item-title>
              </v-list-item>
              <v-list-item
                style="color: aliceblue"
                class="error"
                v-if="!user.help_email"
                dense
                link
                @click="openAtualizarEmailModal = true"
              >
                <v-list-item-avatar>
                  <v-icon small style="color: aliceblue">fa fa-envelope</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Cadastrar Email</v-list-item-title>
                <v-list-item-avatar>
                  <v-icon small style="color: aliceblue">fa fa-exclamation-triangle</v-icon>
                </v-list-item-avatar>
              </v-list-item>
              <!-- <v-list-item v-else dense link @click="openAtualizarEmailModal = true">
                <v-list-item-avatar>
                  <v-icon small>fa fa-envelope</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Atualizar Email</v-list-item-title>
              </v-list-item> -->
              <v-list-item dense link @click="openAtualizarSenhaModal = true">
                <v-list-item-avatar>
                  <v-icon small>fa fa-key</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Alterar senha</v-list-item-title>
              </v-list-item>
              <v-list-item dense link @click="openVerSessoes = true">
                <v-list-item-avatar>
                  <v-icon small>fa fa-podcast</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Acessos</v-list-item-title>
              </v-list-item>
              <v-list-item dense link :to="{ name: 'logout' }">
                <v-list-item-avatar>
                  <v-icon small>fa fa-sign-out-alt</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Sair</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list>
      </v-app-bar>

      <v-dialog v-model="modalOcioso" persistent max-width="500">
        <v-card>
          <v-card-text class="text-center text-h5 pt-4 font-weight-bold">
            Sua sessão está proxima de expirar!
          </v-card-text>
          <v-card-text class="text-center"
            >Esta faltando
            <v-chip class="ma-2" color="orange" text-color="white">
              {{ this.tempoFormatado }}
            </v-chip>
            segundos.</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="
                limparIntervalo(idInterval);
                dataInicial();
                temporizador();
                modalOcioso = false;
              "
            >
              Permanecer logado
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-system-bar color="yellow lighten-5">
        <p class="lineUp caption text--black mt-4">v1.0.4.1941</p>

        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-btn
          v-show="botaoAlerta === true"
          x-small
          class="ma-10"
          outlined
          color="primary"
          @click="novoAlerta = true"
        >
          <v-icon color="secondary">campaign</v-icon> Novo Alerta</v-btn
        >
        <span class="caption text--black mr-2">Tempo de sessão</span>
        <v-icon>mdi-timer-outline</v-icon>
        <span class="caption text--black mr-2">{{ tempoFormatado }}</span>
      </v-system-bar>
      <e-mensagem-alerta
        @bloquearBotaoNovo="block()"
        @situacaoBtnAlerta="
          ($event) => {
            (botaoAlerta = $event), (novoAlerta = false);
          }
        "
        :novo="novoAlerta"
        :texto="textoMensagemAlerta"
      />
      <v-container>
        <slot />
        <v-row style="min-height: 90px">
          <v-col>
            <span></span>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <e-change-password-modal v-model="openAtualizarSenhaModal" />
    <e-atualizar-email-modal v-model="openAtualizarEmailModal" />
    <e-selecionar-franquia-modal v-model="openSelecionarFranquiaModal" />

    <v-dialog v-model="openVerSessoes" width="600px">
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on"> Open Dialog </v-btn>
      </template> -->
      <v-card>
        <v-card-title>
          <span class="text-h5 me-12">Histórico de logins da web</span> <br />
        </v-card-title>
        <v-alert
          dense
          border="left"
          type="warning"
          v-if="!infoUltimosLogins"
          color="primary"
          width="500px"
          class="ms-4"
        >
          Não há registro de logins
        </v-alert>
        <v-card-text v-for="(item, index) in infoUltimosLogins" :key="item.id">
          <v-card class="mx-auto" dark max-width="500" color="blue-grey lighten-5">
            <v-card-title>
              <v-icon
                v-if="
                  item.dispositivo == 'Windows' ||
                  item.dispositivo == 'Linux' ||
                  item.dispositivo == 'MacOS'
                "
                large
                left
                style="font-size: 25px"
                color="blue"
              >
                fa fa-laptop
              </v-icon>
              <v-icon
                style="font-size: 30px"
                color="blue"
                v-else-if="item.dispositivo == 'Android' || item.dispositivo == 'Iphone'"
                large
                left
              >
                fa fa-mobile
              </v-icon>
              <v-icon style="font-size: 25px" color="blue" v-else large left> fa fa-laptop</v-icon>

              <span v-if="index == 0" class="dotAtivado"></span>
              <span v-if="index != 0" class="dotDesativado"></span>
              <span style="color: black; font-size: 18px">{{
                item.dispositivo || "Dispostivo desconhecido"
              }}</span>
              <!-- <span class="ms-13 font-weight-light f-14" style="color: black"></span> -->
              <!-- <span class="ms-12 font-weight-light f-14" style="color: black"></span> -->
            </v-card-title>
            <v-card-text class="text-h7 font-weight-bold" style="color: black">
              <span class="font-weight-light f-14" style="color: black"
                >Acessado em {{ item.data_de_acesso || " " }}</span
              >
              <span class="ms-5 font-weight-light f-14" style="color: black">{{
                item.hora_de_acesso || " "
              }}</span>
              <br />
              <span v-if="item.cidade && item.ip" style="color: black; font-size: 16px"
                >{{ item.cidade || " " }} - {{ item.estado || " " }}</span
              >
              <span class="ms-5" style="color: black; font-size: 14px">{{ item.ip || " " }}</span>
            </v-card-text>

            <v-card-actions>
              <v-list-item class="grow">
                <!-- <v-list-item-avatar color="grey darken-3">
                  <v-img
                    class="elevation-6"
                    alt=""
                    src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                  ></v-img>
                </v-list-item-avatar> -->

                <!-- <v-list-item-content> -->

                <span style="color: black">Visto em {{ item.pais || "Brasil" }}</span>
                <!-- </v-list-item-content> -->

                <v-row align="center" justify="end">
                  <!-- <v-icon class="mr-1"> mdi-heart </v-icon>
                  <span class="subheading mr-2">256</span>
                  <span class="mr-1">·</span>
                  <v-icon class="mr-1"> mdi-share-variant </v-icon>
                  <span class="subheading">45</span> -->
                </v-row>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="green darken-1" text @click="openVerSessoes = false"> Disagree </v-btn> -->
          <v-btn
            color="blue"
            class="white--text"
            type="button"
            small
            @click="openVerSessoes = false"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EChangePasswordModal from "../../../components/EChangePasswordModal.vue";
import EAtualizarEmailModal from "../../../components/EAtualizarEmailModal.vue";
import EMensagemAlerta from "../../../components/EMensagemAlerta.vue";

const exibirFinanceiro = process.env.VUE_APP_ATIVAR_FINANCEIRO === "true";
export default {
  name: "MainTemplate",
  components: {
    EChangePasswordModal,
    EAtualizarEmailModal,
    EMensagemAlerta,
  },
  props: {
    hideFranquia: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
    ...mapGetters("Franquias", ["franquias"]),
    ...mapGetters("MainTemplate", ["menuOpen"]),
  },
  mounted() {
    // this.$Tawk.$setOnLoadFunction(() => this.$Tawk.$showWidget());
    this.dataInicial();
    this.temporizador();
    this.infoUltimosLogins = JSON.parse(localStorage.getItem("infoUltimosLogins"));

    // verificar status dos dados invisiveis
    this.getUserAuth();

    // console.log(this.infoUltimosLogins);
  },
  data() {
    return {
      permitirEdicaoAlert: false,
      botaoAlerta: true,
      novoAlerta: false,
      textoMensagemAlerta: null,
      dadosOcultos: false,
      idInterval: null,
      modalOcioso: false,
      active: false,
      tempo: null,
      tempoFormatado: null,
      durabilidadeDaVidaDoUsuario: 0,
      selectedItem: 0,
      openAtualizarSenhaModal: false,
      openSelecionarFranquiaModal: false,
      openAtualizarEmailModal: false,
      openVerSessoes: false,
      infoUltimosLogins: [],
      exibirFinanceiro,
      menu: {
        items: [
          {
            icon: "fa fa-home",
            title: "Início",
            exibir: true,
            children: [{ title: "Ir para Início", to: { name: "home" }, icon: "" }],
          },
          {
            icon: "fa fa-user-graduate",
            title: "Matrículas",
            exibir: true,
            children: [
              { title: "Ver Matrículas", to: { name: "matriculas" }, icon: "" },
              // { title: "Adicionar matricula", to: "#", icon: "" },
              { title: "Ver Alunos", to: { name: "alunos" }, icon: "" },
              {
                title: "Pesquisa avançada",
                to: { name: "alunos-pesquisa-avancada" },
                icon: "",
              },
            ],
          },
          {
            icon: "fa fa-graduation-cap",
            title: "Turmas",
            exibir: true,
            children: [
              { title: "Ver turmas", to: { name: "turmas" }, icon: "" },
              // { title: "Adicionar turmas", to: "#", icon: "" },
              // { type: "spacer" },
              {
                title: "Gestão de séries",
                to: { name: "series" },
                icon: "fa fa-bookmark",
                permissions: ["series.*", "series.criar"],
              },
              {
                title: "Gestão de disciplinas",
                to: { name: "disciplinas" },
                icon: "fa fa-bookmark",
                permissions: ["disciplinas.*", "disciplinas.criarDisciplina"],
              },
              // { title: "Gestão de disciplinas", to: "#", icon: "" },
              // { title: "Gestão de cursos", to: "#", icon: "" },
            ],
          },
          {
            icon: "fa fa-chalkboard-teacher",
            title: "Diário online",
            exibir: true,
            children: [
              {
                title: "Listar todos",
                to: { name: "gestoesDeAulas" },
                icon: "",
                permissions: ["gestoesDeAulas.*", "gestoesDeAulas.criar"],
              },
            ],
          },
          {
            icon: "fa fa-users",
            title: "Usuários",
            exibir: true,
            children: [
              {
                title: "Ver Professores",
                to: { name: "professores" },
                icon: "fa fa-user-tie",
                permissions: ["users.*"],
              },
              {
                title: "Outros",
                to: { name: "usuarios" },
                icon: "fa fa-bookmark",
                permissions: ["users.*"],
              },
            ],
          },
          {
            icon: "fa fa-money",
            title: "Financeiro",
            exibir: exibirFinanceiro,
            children: [
              {
                title: "Mensalidades",
                to: { name: "mensalidades" },
                permissions: "financeiro.baixarParcela",
                icon: "",
              },
              {
                title: "Gestão de Caixas",
                to: { name: "caixas" },
                permissions: "caixas.abrir",
                icon: "",
              },
            ],
          },
          {
            icon: "mdi-account-network",
            title: "Administração",
            exibir: true,
            children: [
              {
                title: "Dashboard",
                to: { name: "admin" },
                icon: "",
              },
              {
                title: "Franquias",
                to: { name: "franquias" },
                permissions: ["franquias.*", "franquias.criarFranquia"],
                icon: "",
              },
              // {
              //   title: "Inep",
              //   to: { name: "inep" },
              //   icon: "",
              // },
            ],
          },
        ],
        width: "250px",
      },
    };
  },
  methods: {
    block() {
      this.botaoAlerta = false;
    },
    async getUserAuth() {
      const currentUser = await this.$services.authService.currentUser();
      this.dadosOcultos = currentUser.dados_ocultos;
    },
    async mostrarDadosOcultos(resposta) {
      try {
        this.$loaderService.open("Procurando dados");
        const res = await this.$services.authService.mostrarDadosOcultos(resposta);
        this.$loaderService.close();
      } catch (error) {
        this.$loaderService.close();
        this.$handleError(error);
      }

      window.location.reload();
    },

    toggleMenu() {
      this.$store.commit("MainTemplate/setMenuOpen", !this.menuOpen);
    },
    dataInicial() {
      const data = Date.now();
      const dataConvertida = new Date(data);
      // dataConvertida.setTime(0);
      dataConvertida.setHours(0);
      dataConvertida.setMinutes(0);
      dataConvertida.setSeconds(0);
      // estabeler temporizador
      dataConvertida.setMinutes(this.durabilidadeDaVidaDoUsuario);
      dataConvertida.setHours(2);
      this.tempo = dataConvertida;
    },
    temporizador() {
      this.idInterval = setInterval(() => {
        const data = new Date(this.tempo);
        data.setSeconds(data.getSeconds() - 1);
        this.tempo = data.toISOString();
        const h = this.zeroFill(data.getHours());
        const m = this.zeroFill(data.getMinutes());
        const s = this.zeroFill(data.getSeconds());
        this.tempoFormatado = `${h}:${m}:${s}`;
        const somaTempo = parseInt(
          this.zeroFill(data.getHours()) +
            this.zeroFill(data.getMinutes()) +
            this.zeroFill(data.getSeconds()),
          10
        );
        if (parseInt(h, 10) === 0 && parseInt(m, 10) === 0 && parseInt(s, 10) === 0) {
          this.$router.push({ name: "logout" });
          return;
        }
        if (!somaTempo) {
          this.limparIntervalo(this.idInterval);
        }
        if (parseInt(h, 10) === 0 && parseInt(m, 10) === 10 && parseInt(s, 10) === 0) {
          this.modalOcioso = true;
        }
      }, 1000);
    },
    limparIntervalo(idInterval) {
      clearInterval(idInterval);
    },
    zeroFill(n) {
      return n <= 9 ? `0${n}` : `${n}`;
    },
  },
};
</script>

<style scoped>
.lineUp {
  animation: 3s anim-lineUp ease-out infinite;
}
@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(25%);
  }
  15% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
.dotAtivado {
  height: 15px;
  width: 15px;
  background-color: rgb(32, 224, 112);
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.dotDesativado {
  height: 15px;
  width: 15px;
  background-color: rgb(241, 60, 5);
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.f-14 {
  font-size: 14px;
}
</style>
