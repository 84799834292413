<template>
  <div class="wrapper">
    <v-card elevation="2" class="login-card">
      <v-card-title class="d-flex justify-center">
        <img src="/img/logo.png" width="100" />
      </v-card-title>
      <v-card-title>Validação de Documentos</v-card-title>
      <v-card-text>
        <ValidationObserver v-slot="{ handleSubmit }">
          <v-form ref="form" @submit.prevent="handleSubmit(validarCertificado)">
            <ValidationProvider name="Código" rules="required" v-slot="{ errors }">
              <v-text-field
                v-model="codigo"
                filled
                prepend-inner-icon="mdi-account-outline"
                label="Digite o código"
                required
                :error-messages="errors"
              />
            </ValidationProvider>
            <v-btn block color="primary" type="submit"> Validar Certificado </v-btn>
          </v-form>
        </ValidationObserver>
      </v-card-text>
    </v-card>

    <v-dialog v-model="modalOpen" max-width="500">
      <v-card>
        <v-card-title class="headline">
          <v-icon :color="resposta?.status === 'success' ? 'green' : 'red'" class="mr-2">
            {{ resposta?.icone }}
          </v-icon>
          {{ resposta?.titulo }}
        </v-card-title>
        <v-card-text>
          {{ resposta?.mensagem }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="modalOpen = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "validarCertificado",
  data() {
    return {
      codigo: null,
      resposta: null,
      modalOpen: false,
    };
  },
  async mounted() {
    const codigoUrl = this.$route.query.codigo;
    if (codigoUrl) {
      this.codigo = codigoUrl;
      await this.validarCertificado(codigoUrl);
    }
  },
  methods: {
    async validarCertificado() {
      try {
        const resposta = await this.$services.matriculasService.validarCertificado(this.codigo);
        this.resposta = resposta;
        this.modalOpen = true;
        // this.$toast.success("E: ", this.resposta);
      } catch (error) {
        this.resposta = {
          status: "error",
          titulo: "Erro na Validação",
          mensagem: "Não foi possível validar o certificado. Tente novamente mais tarde.",
          icone: "mdi-alert-circle",
        };
        this.$handleError(error);
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-image: url("../assets/background.png");
  background-size: cover;
}
.wrapper > .login-card {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  max-width: 600px;
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
